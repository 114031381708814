// Action Types
export enum MerchantAgencyType {
  SET_DATA_TABLE_LIST = 'SET_DATA_TABLE_LIST',
  SET_JOIN_PARENT = 'SET_JOIN_PARENT',
  SET_IDCARD_PHOTO = 'SET_IDCARD_PHOTO',
  SET_NPWP_PHOTO = 'SET_NPWP_PHOTO',
  SET_BISNIS_TYPE = 'SET_BISNIS_TYPE',
  SET_PARENT = 'SET_PARENT',
  SET_TO_DEFAULT = 'SET_TO_DEFAULT',
}

export interface MerchantAgencyState {
  listData: ITableKey[];
  Parent: IParent;
  IsJoinParent: string;
  NpwpPhoto: File;
  IdCardPhoto: File;
  BusinessType: string;
}

export interface IParent {
  parentname: string;
  mcode: string;
  bankinformation: {
    bankName: string;
    bankCode: string;
    accountName: string;
    accountNUmber: string;
  };
}
export interface ITableKey {
  id: number;
  IdCardPhoto: string;
  NpwpPhoto: string;
  BusinessType: string;
  OwnerName: string;
  IdCardNumber: string;
  OwnerPhone: string;
  PicTechEmail: string;
  MqMerchantName: string;
  MCC: string;
  ServiceType: string;
  OwnerAddress: string;
  MqCity: string;
  OwnerZip: string;
  IsOnlineSales: string;
  Criteria: string;
  MqTypeQr: string;
  DocumentCompleteness: string;
  BizUrl: string;
  QrisReady: string;
  PaymentMethod: string;
  EstimatedAmount: string;
  BankAccountNumber: string;
  BankAccountName: string;
  MApiPublic: string;
}

interface setParent {
  type: MerchantAgencyType.SET_PARENT;
  payload: IParent;
}
interface setJoinParent {
  type: MerchantAgencyType.SET_BISNIS_TYPE;
  payload: string;
}
interface setBusinessType {
  type: MerchantAgencyType.SET_JOIN_PARENT;
  payload: string;
}
interface setIdCardPhoto {
  type: MerchantAgencyType.SET_IDCARD_PHOTO;
  payload: File;
}
interface setNpwpPhoto {
  type: MerchantAgencyType.SET_NPWP_PHOTO;
  payload: File;
}

interface setDataList {
  type: MerchantAgencyType.SET_DATA_TABLE_LIST;
  payload: Array<ITableKey>;
}
interface setToDefault {
  type: MerchantAgencyType.SET_TO_DEFAULT;
  payload?: MerchantAgencyState;
}

export type merchantAgencyActions =
  | setJoinParent
  | setDataList
  | setToDefault
  | setIdCardPhoto
  | setBusinessType
  | setParent
  | setNpwpPhoto;
