import { createTheme } from '@mui/material';

export const fontTheme = createTheme({ typography: { allVariants: { fontFamily: 'Manrope' } } });
export const colorTheme = createTheme({
  palette: {
    primary: { main: '#009883', contrastText: '#FFF' },
    success: { main: '#21AF7D', contrastText: '#fff' },
    warning: { main: '#EE4E2B', contrastText: '#fff' },
  },
});
export const btnTheme = createTheme({
  ...colorTheme,
  typography: {
    fontFamily: 'Manrope',
    allVariants: { textTransform: 'capitalize', fontWeight: 'bold', fontSize: 16 },
  },
  components: {
    MuiButton: {
      // styleOverrides: { outlined: { border: '2px solid' } },
    },
  },
});

export const checkboxTheme = createTheme({
  palette: {
    primary: { main: '#21AF7D' },
  },
});

export const paginationTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPaginationItem-root': {
            '&.Mui-selected': {
              background: '#18AD65',
              color: 'white',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: { color: '#18AD65' },
      },
    },
  },
});

export const textFieldTheme = createTheme({
  ...colorTheme,
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiTextField-root': {
            '&.Mui-focused': {
              color: '#18AD65',
            },
          },
        },
      },
    },
  },
});

export const formControlTheme = createTheme({ ...fontTheme, ...colorTheme });
