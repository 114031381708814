/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';
import { MerchantAgencyState, merchantAgencyActions, MerchantAgencyType } from 'redux/types/merchantAgency';

const initialState = {
  listData: [],
  IsJoinParent: '',
  BusinessType: '',
  IdCardPhoto: null,
  NpwpPhoto: null,
  Parent: {
    parentname: '',
    mcode: '',
    bankinformation: {
      bankName: '',
      bankCode: '',
      accountName: '',
      accountNUmber: '',
    },
  },
} as MerchantAgencyState;

export default (state: MerchantAgencyState = initialState, { type, payload }: merchantAgencyActions) => {
  switch (type) {
    case MerchantAgencyType.SET_TO_DEFAULT:
      return initialState;
    case MerchantAgencyType.SET_JOIN_PARENT:
      return update(state, {
        IsJoinParent: { $set: payload },
      });

    case MerchantAgencyType.SET_DATA_TABLE_LIST:
      return update(state, {
        listData: { $set: payload },
      });
    case MerchantAgencyType.SET_IDCARD_PHOTO:
      return update(state, {
        IdCardPhoto: { $set: payload },
      });
    case MerchantAgencyType.SET_NPWP_PHOTO:
      return update(state, {
        NpwpPhoto: { $set: payload },
      });
    case MerchantAgencyType.SET_DATA_TABLE_LIST:
      return update(state, {
        listData: { $set: payload },
      });
    case MerchantAgencyType.SET_BISNIS_TYPE:
      return update(state, {
        BusinessType: { $set: payload },
      });
    case MerchantAgencyType.SET_PARENT:
      return update(state, {
        Parent: { $set: payload },
      });

    default:
      return state;
  }
};
