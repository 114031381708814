import React from 'react';
import FilterIcon from 'assets/icons/filter-table.png';
import { BasicPopover } from 'Components/Popover';
import { FilterBtnProps } from 'types/Button';
import { ChexkList, CustomFilterInput } from './variants';
import { Calendar, DateRange, Range } from 'react-date-range';
import moment from 'moment';
import { BasicButton } from '..';
import { Autocomplete, Badge, Box, FormControlLabel, Radio, TextField, ThemeProvider } from '@mui/material';
import { BasicCheckbox } from 'Components/Checkbox';
import { formControlTheme } from 'configs/theme';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const Component: React.FC<FilterBtnProps> = (props) => {
  const anchorRef = React.useRef(null);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [dates, setDates] = React.useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
    color: '#18AD65',
  });

  React.useEffect(() => {
    if (props.type === 'date') {
      props.range?.set({
        start: moment(dates.startDate).format('YYYY-MM-DD'),
        end: moment(dates.endDate).format('YYYY-MM-DD'),
      });
    }
  }, [dates.startDate, dates.endDate]);

  const handleSelect = (ranges: { selection: Range }) => {
    if (props?.rangesDate) {
      props?.rangesDate?.set(ranges.selection);
    } else {
      setDates(ranges.selection);
    }
  };

  const handleToggle = () => {
    setFilterOpen(!filterOpen);
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.type === 'text') {
      props.text?.set(event.target.value);
    }
  };

  const handleCheckboxChange = (value: string) => {
    if (props.type === 'multi-check') {
      if (props.selection?.val?.includes(value)) {
        props.selection?.set(props.selection?.val?.filter((option) => option !== value));
      } else {
        props.selection?.set([...props.selection?.val, value]);
      }
    }
  };

  const handleOptionChange = (val: string) => {
    if (props.type === 'option') {
      props.selected?.set(val);
    }
  };

  const handleSingleDate = (v: Date) => {
    if (props.type === 'pick-date') {
      props.date.set(v);
    }
  };

  function typesMode() {
    if (props.type === 'text') {
      return (
        <CustomFilterInput
          type='text'
          value={props.text?.val}
          onChange={handleInput}
          id={props.id}
          placeholder={props.text?.placeholder}
        />
      );
    } else if (props.type === 'number') {
      return (
        <>
          <CustomFilterInput
            type='number'
            value={props.text?.val}
            onChange={handleInput}
            id={props.id}
            placeholder={props.text?.placeholder}
          />
          ss
        </>
      );
    } else if (props.type === 'date') {
      return (
        <DateRange
          editableDateInputs={true}
          onChange={handleSelect}
          moveRangeOnFirstSelection={false}
          ranges={props?.rangesDate?.val || [dates]}
          color='#18AD65'
        />
      );
    } else if (props.type === 'option') {
      return (
        <Box display={'flex'} flexDirection={'column'} sx={{ mx: 2 }} overflow={'auto'} maxHeight={300}>
          <ThemeProvider theme={formControlTheme}>
            {props.options?.map((opt) => (
              <FormControlLabel
                key={opt.label}
                control={<Radio checked={props.selected?.val === opt.value} />}
                onChange={() => handleOptionChange(opt.value)}
                label={opt.label}
              />
            ))}
          </ThemeProvider>
        </Box>
      );
    } else if (props.type === 'multi-check') {
      return (
        <Box display={'flex'} flexDirection={'column'} sx={{ mx: 2 }}>
          <ThemeProvider theme={formControlTheme}>
            {props.options?.map((option) => (
              <FormControlLabel
                key={option.label}
                className='font-bold'
                control={<BasicCheckbox checked={props.selection?.val?.includes(option.value)} />}
                onChange={() => handleCheckboxChange(option.value)}
                label={option.label}
              />
            ))}
          </ThemeProvider>
        </Box>
      );
    } else if (props.type === 'check') {
      return <ChexkList list={props.list} selected={props.selected} />;
    } else if (props.type === 'pick-date') {
      return (
        <Box display={'flex'} mx={'auto'}>
          <Calendar date={props.date.val} onChange={handleSingleDate} />;
        </Box>
      );
    } else if (props.type === 'autocomplete') {
      return (
        <Box display={'flex'} flexDirection={'column'} sx={{ mx: 2, my: 2 }}>
          <Autocomplete
            limitTags={1}
            options={props?.options || []}
            multiple={true}
            size='small'
            value={props?.selection?.val || []}
            onChange={(event, newValue) => {
              props?.selection?.set(newValue);
            }}
            className='cursor-pointer'
            renderInput={(params) => (
              <>
                <TextField {...params} variant='outlined' placeholder={props?.selection?.placeholder} />
              </>
            )}
            sx={{ width: 'auto', minWidth: '150px', cursor: 'pointer' }}
          />
        </Box>
      );
    }
  }

  const isDisabled =
    (props.type === 'text' && props.text?.val?.length === 0) ||
    (props.type === 'date' && props.range?.val?.start?.length === 0 && props.range?.val?.end?.length === 0) ||
    ((props.type === 'option' || props.type === 'check') && props.selected?.val?.length === 0) ||
    (props.type === 'pick-date' && props.date?.val === null) ||
    (props.type === 'multi-check' && props.selection?.val?.length === 0);

  return (
    <>
      <button className='gap-2 cursor-pointer' onClick={handleToggle} ref={anchorRef}>
        <Badge
          color='secondary'
          variant='dot'
          invisible={props?.filterActive ? !props?.filterActive : true}
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: '#FF3535',
            },
          }}
        >
          <img src={FilterIcon} alt='filter' className='w-4' />
        </Badge>
      </button>

      <BasicPopover
        open={filterOpen}
        anchorEl={anchorRef.current}
        onClose={() => setFilterOpen(false)}
        width={props?.width ? props.width : props.type === 'date' ? 330 : 250}
        position='center'
      >
        {typesMode()}
        <div className='flex p-2 justify-end gap-4'>
          <BasicButton variant='text' color='error' disableElevation sx={{ p: 0 }} onClick={props.onClear}>
            Reset
          </BasicButton>
          <BasicButton variant='contained' color='success' disableElevation onClick={props.onSubmit}>
            Apply
          </BasicButton>
        </div>
      </BasicPopover>
    </>
  );
};

export default Component;
